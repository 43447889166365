<template>
	<v-container>
		<v-card class="mb-10">
			<v-card-text>
				<v-form ref="form" enctype="multipart/form-data">
					<v-row>
						<v-col>
							<!-- Preview Image -->
							<v-avatar v-if="imageUrl" class="profile ml-6" size="200">
								<v-img :src="imageUrl"></v-img>
							</v-avatar>
						</v-col>
					</v-row>
					<v-row>
						<!-- Avatar -->
						<v-col cols="12" md="5">
							<v-file-input
								accept="image/png, image/jpeg, image/bmp"
								:placeholder="$t('pickAvatar')"
								prepend-icon="mdi-camera"
								:label="$t('avatar')"
								@change="previewImage"
								@click:clear="clearImage"
								v-model="image"
							>
							</v-file-input>
						</v-col>

						<!-- Name -->
						<v-col cols="12" md="4">
							<v-text-field
								v-model="name"
								:label="$t('name') + '*'"
								:rules="nameRules"
								required
							></v-text-field>
						</v-col>

						<!-- Type -->
						<v-col cols="12" md="3">
							<v-select
								v-model="type"
								:items="types"
								:label="$t('type')"
							></v-select>
						</v-col>
					</v-row>

					<v-row>
						<!-- Sex -->
						<v-col cols="12" md="2">
							<v-select
								v-model="sex"
								:items="sexOptions"
								:label="$t('sex')"
							></v-select>
						</v-col>

						<!-- Breed -->
						<v-col cols="12" md="4">
							<v-text-field v-model="breed" :label="$t('breed')"></v-text-field>
						</v-col>

						<!-- Date -->
						<v-col cols="12" md="4">
							<v-menu
								v-model="dateMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								offset-y
								transition="scale-transition"
								min-width="150px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="birthdate"
										:label="$t('birthdate')"
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="birthdate"
									:locale="this.$i18n.locale || 'bg'"
									:first-day-of-week="1"
									@input="dateMenu = false"
								></v-date-picker>
							</v-menu>
						</v-col>

						<!-- Weight -->
						<v-col cols="12" md="2">
							<v-text-field
								v-model="weight"
								:label="$t('weight')"
								type="text"
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<!-- Chip -->
						<v-col cols="12" md="5">
							<v-text-field
								v-model="chip"
								:label="$t('chip')"
								type="text"
							></v-text-field>
						</v-col>
					</v-row>

					<!-- Loader -->
					<v-row>
						<v-col align="center">
							<v-progress-circular
								v-if="isLoading"
								:width="3"
								color="green"
								indeterminate
								class="text-center"
							></v-progress-circular>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" dark @click="goBack">
					{{ $t('back') }}
				</v-btn>
				<v-btn color="primary" @click="save">
					{{ $t('save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	data() {
		return {
			name: '',
			breed: '',
			type: '',
			sex: '',
			birthdate: '',
			chip: '',
			weight: '',
			isLoading: false,
			imageUrl: null,
			image: null,
			dateMenu: false,
			nameRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	computed: {
		types() {
			return [
				this.$i18n.t('dog'),
				this.$i18n.t('cat'),
				this.$i18n.t('hamster'),
				this.$i18n.t('ferret'),
				this.$i18n.t('bird'),
				this.$i18n.t('reptile'),
				this.$i18n.t('other'),
			];
		},
		sexOptions() {
			return [this.$i18n.t('male'), this.$i18n.t('female')];
		},
	},
	methods: {
		...mapActions('pets', ['createPet']),
		goBack() {
			this.$router.go(-1);
		},
		previewImage(image) {
			this.imageUrl = URL.createObjectURL(image);
		},
		clearImage() {
			this.imageUrl = null;
		},
		async save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;

			let formData = new FormData();

			formData.append(`file`, this.image);
			formData.append('name', this.name);
			formData.append('type', this.type);
			formData.append('sex', this.sex);
			formData.append('breed', this.breed);
			formData.append('birthdate', this.birthdate);
			formData.append('chip', this.chip);
			formData.append('weight', this.weight);

			try {
				await this.createPet(formData);

				this.isLoading = false;

				this.image = null;
				this.imageUrl = null;
				this.name = '';
				this.type = '';
				this.sex = '';
				this.breed = '';
				this.birthdate = '';
				this.weight = '';

				this.$router.go(-1);
			} catch (error) {
				console.error(error);
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
